import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from "framer-motion";
import ClockDisplay from './ClockDisplay'

const Login = ({ handleLogin }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const [currentTime, setCurrentTime] = useState('')
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [showPassword, setShowPassword] = useState(false);

    const handleLoginSubmit = (e) => {
        e.preventDefault();

        // Predefined username and password
        const predefinedUsername = 'Drukair';
        const predefinedPassword = 'Drukair@2024!';

        if (username === predefinedUsername && password === predefinedPassword) {
            // Store authentication state in local storage
            localStorage.setItem('isAuthenticated', 'true');
            handleLogin(); // Call handleLogin to update state
            navigate('/'); // Redirect to home page
        } else {
            alert('Wrong authentication!');
        }
    };

    useEffect(() => {
        const updateClock = () => {
            const now = new Date();
            const hours = now.getHours();
            const minutes = now.getMinutes();
            const seconds = now.getSeconds();
            const ampm = hours >= 12 ? 'PM' : 'AM';

            const formattedHours = hours % 12 || 12; // Convert to 12-hour format, using '12' for '0'
            const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
            const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

            setCurrentTime(`${formattedHours}:${formattedMinutes}:${formattedSeconds} ${ampm}`);
        };

        const interval = setInterval(updateClock, 1000);
        return () => clearInterval(interval); // Clean up on component unmount
    }, []);

    const cloudFloatingEffect3 = {
        animate: {
            y: [0, -16, 0],
        },
        transition: {
            duration: 6,
            ease: "easeInOut",
            repeat: Infinity,
            repeatType: "mirror",
        },
    };

    return (
        <div className="relative h-full overflow-hidden">
            <div className="fixed inset-0">
                <video
                    autoPlay
                    loop
                    muted
                    className="w-full h-full object-cover"
                >
                    <source src="/bg_video.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>

            {/* Display the current time at the top right corner */}
            <motion.div animate={cloudFloatingEffect3.animate} className="relative h-full">
                <ClockDisplay />
            </motion.div>

            <div className="absolute top-2 right-4">
                <motion.div animate={cloudFloatingEffect3.animate}
                    className="text-gray-800 text-center">
                    <button
                        onClick={() => setIsModalOpen(true)}
                        className="mt-4 px-6 py-2 text-gray-900 bg-white/5 backdrop-blur-lg border border-white/30 rounded-full hover:border-purple-400 hover:bg-white/20 transition">
                        Login to Dashboard
                    </button>
                </motion.div>
            </div>

            {/* Modal */}
            {isModalOpen && (
                <div className="fixed inset-0 bg-black/40 backdrop-blur-sm flex items-center justify-center z-20">
                    <div className="bg-white/20 backdrop-blur-lg p-6 rounded shadow-md w-80">
                        <h2 className="mb-4 text-lg font-bold text-center text-white">Login</h2>
                        <form onSubmit={handleLoginSubmit}>
                            <div className="mb-4">
                                <label className="block text-sm font-semibold mb-2 text-white" htmlFor="username">Username</label>
                                <input
                                    type="text"
                                    id="username"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    className="w-full p-2 border border-white/30 bg-white/20 backdrop-blur-md text-white rounded"
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-sm font-semibold mb-2 text-white" htmlFor="password">Password</label>
                                <div className="relative">
                                    <input
                                        type={showPassword ? "text" : "password"}
                                        id="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        className="w-full p-2 border border-white/30 bg-white/20 backdrop-blur-md text-white rounded"
                                        required
                                    />
                                    <button
                                        type="button"
                                        className="absolute right-3 top-2 text-black"
                                        onClick={() => setShowPassword(!showPassword)}
                                    >
                                        {showPassword ? "Hide" : "Show"}
                                    </button>
                                </div>
                            </div>
                            <button type="submit" className="w-full bg-blue-500/70 text-white p-2 rounded-lg hover:bg-blue-600/70">
                                Login
                            </button>
                            <button
                                type="button"
                                onClick={() => setIsModalOpen(false)}
                                className="mt-2 w-full text-white p-2">
                                Cancel
                            </button>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Login;
